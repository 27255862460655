import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import News from 'components/News/News';

const LIMIT = 20;
const NewsPage = ({ data }) => (
  <Layout page="news">
    <News data={data.allContentfulArticle.edges} />
  </Layout>
);

export default NewsPage;

export const query = graphql`
  query {
    allContentfulArticle(limit: 20) {
      edges {
        node {
          id
          slug
          typeOfService
          featured
          createdAt
          date
          image {
            fixed(height: 540 width: 736) {
              src
            }
          }
          thumbnail { file { url } }
          title
          shortDescription {
            shortDescription
          }
          content {
            json
          }
        }
      }
    }
  }
`;
