import React, { useState } from 'react';
import _ from 'lodash';

import Text from 'components/UI/Text/Text';
import Section from 'components/UI/Section/Section';
import Card from 'components/UI/Card/Card';
import Filters from 'components/UI/Filters/Filters';

const filtersConfig = [
  {
    name: 'featured',
    label: 'All articles',
    options: [
      {
        value: '',
        title: 'All articles',
      },
      {
        value: 'featured',
        title: 'Featured',
      },
    ],
  },
];

const News = ({ data }) => {
  const initialVals = filtersConfig.reduce(
    (acc, { name }) => ({
      ...acc,
      [name]: 'all',
    }),
    {}
  );

  const [filters, setFilters] = useState(initialVals);
  const filteredArticles = filters.featured === 'featured'
    ? data.filter(({ node }) => node.featured)
    : data.sort(
      (
        { node: { featured: fa }},
        { node: { featured: fb }}
      ) => fa && fb ? 0 : fa ? -1 : 1,
    );

  return (
    <Section>
      <Text as="h2" line stack>News</Text>
      <Filters config={filtersConfig} onChange={setFilters} />
      <ul>
        {filteredArticles
          .map(({ node }, i) => {
            const {
              id,
              slug,
              typeOfService,
              image: {
                // file: { url: imageUrl },
                fixed: { src: imageFixedUrl },
              },
              thumbnail,
              createdAt,
              title,
              shortDescription,
              content: { json: contentJSON },
            } = node;

            const thumbnailUrl = _.get(thumbnail, 'file.url');

            return (
              <Card
                key={id}
                icon={thumbnailUrl || imageFixedUrl}
                iconCover
                tag={typeOfService}
                date={createdAt}
                title={title}
                subtitle={Object(shortDescription).shortDescription}
                linkText="READ ARTICLE"
                href={`/news/${slug}/`}
                expo={i === 0}
                bgColor="#fff"
              />
            );
          }
        )}
      </ul>
    </Section>
  );
}

export default News;
